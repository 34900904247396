import { useEffect, useState } from 'react';
import { UserStore } from '../stores/user-store';


export const useSession = (): [any, boolean] => {
    const [isLoggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        if (UserStore.getSession()) return setLoggedIn(true);
    }, [])

    return [UserStore.getSession(), isLoggedIn];

}