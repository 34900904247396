import { ReactComponent as KakaoIcon} from "../assets/images/logo_kakao.svg"

import { createButton } from "react-social-login-buttons";

const config = {
    text: "Log in with Facebook",    
    icon: KakaoIcon,    
    iconFormat: (name: string) => `fa fa-${name}`,
    style: { background: "#FEE500", color: "#000000" },
    activeStyle: { background: "#F0E100" }
};
/** My Facebook login button. */
const KakaoLoginButton = createButton(config);

export default KakaoLoginButton;
