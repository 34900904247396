import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Login } from './pages/login';
import { Home } from './pages/home';
import { Layout } from './layout/layout';
import { ThemeProvider, createTheme } from '@mui/material';
import { useState } from 'react';
import { OauthRedirected } from './pages/oauth_redirected';


function App() {
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: '#ffffff',
        contrastText: '#aba301',
      },
      secondary: {
        main: '#f0f0f0'
      }
    },
    typography: {
      fontFamily: [
        "Futura"
      ].join(','),
    },
  });

  const [title, setTitle] = useState('Base web react');

  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <Layout title={title}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login/oauth/redirected" element={<OauthRedirected />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </ThemeProvider >
  );
}

export default App;
