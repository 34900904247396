
import { Navigation } from '@mui/icons-material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


export const OauthRedirected = () => {


  const location = useLocation();
  const [iss, setIss] = useState("")

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const code = params.get('code');
    console.log(code)
    const iss = params.get("state");
    // FIXME: 
    // 이 부분은 서버에서 처리해야 한다. 일단은 테스트를 위해 클라이언트에서 처리함
    const REDIRECT_URI = "http://localhost:3000/login/oauth/redirected"
    const kakao_body = {
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_KAKAO_CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      code: code
    }
    const kakao_token_url = "https://kauth.kakao.com/oauth/token"
    const google_token_url = "https://oauth2.googleapis.com/token"

    const google_body = {
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: REDIRECT_URI,
      code: code
    }

    const headers = { headers: { "Content-Type": "application/x-www-form-urlencoded;charset=utf-8" } }
    axios.post(iss === "kakao" ? kakao_token_url : google_token_url, iss === "kakao" ? kakao_body : google_body, headers)
      .then((data) => {
        console.log(data);
      })
      .catch((e) => { console.log(e) })

    // window.location.href = "http://localhost:3000"
  }, [location.search]);


  return (<div>Hi ${iss}</div>)
}