import React from "react"
import { Footer } from "./footer";
import { HeaderMain } from "./header";
import { Box } from "@mui/material";

interface LayoutProps {
    children: React.ReactNode,
    title: string
}

export const Layout = ({ children, title }: LayoutProps) => {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <HeaderMain title={title} />
            <main>
                {children}
            </main>
        </Box>
    );
}