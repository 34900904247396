import { Link, useNavigate } from "react-router-dom";
import { useSession } from "../hooks/use-sesison";
import { UserStore } from "../stores/user-store";
import React from "react";
import { AppBar, Avatar, Box, Button, Container, IconButton, Toolbar, Typography } from "@mui/material";
import { IcecreamOutlined, InvertColorsOffTwoTone, MenuBook, Money } from "@mui/icons-material";


const HeaderAuthLink = () => {
    const [session, isLoggedIn] = useSession();
    const navigate = useNavigate();

    return (
        <React.Fragment>
            {
                isLoggedIn ? (
                    <div>
                        <Link to="/my">{session.id}</Link>
                        <Button variant="outlined" size="small" onClick={(event) => {
                            event.preventDefault();
                            UserStore.clearSession();
                            fetch("http://127.0.0.1:8000/logout")
                                .catch(() => { })
                                .then(() => { })
                            window.location.href = "/"
                        }}>
                            Sign out
                        </Button>
                    </div >
                ) : (
                    <div>
                        <Button variant="outlined" size="small" onClick={(event) => {
                            navigate("/login");
                        }} >
                            Sign in
                        </Button>
                    </div>)}
        </React.Fragment>
    );
}

const LogoLinkStyle = {
    textDecoration: "none",
    color: "green"
}

interface HeaderMainProps {
    title: string
}

export const HeaderMain = ({ title }: HeaderMainProps) => {
    const navigate = useNavigate();
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" >
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        <Link to="/" style={LogoLinkStyle}>
                            {title}
                        </Link>
                    </Typography>
                    <IconButton onClick={() => { navigate("/login") }}>
                        <Money />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    );
}