import setAuthorizationToken from "../utils/set_authorization_token";
import jwt from 'jwt-decode';


export interface Session {
    sub: string;
    iss: string,
    email: string;
    verified_email: boolean;
    name: string;
    given_name: string;
    family_name: string;
    picture: string;
    locale: string;
    exp: number,
}

class UserStoreClass {
    private _session: Session | null = null;
    public setSession(session: Session) {
        this._session = session;
    }

    public getSession(): Session | null {
        if (this._session == null) {
            const jwtToken = window.document.cookie.replace(
                /(?:(?:^|.*;\s*)jwt\s*=\s*([^;]*).*$)|^.*$/,
                '$1'
            );
            if (jwtToken !== '') {
                const session: Session = jwt(jwtToken);
                console.log(session);
                this.setSession(session);
                setAuthorizationToken(jwtToken);
            }
        }
        return this._session;
    }

    public clearSession() {
        window.document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/';
    }
}

export const UserStore = new UserStoreClass();
