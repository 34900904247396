import axios from 'axios';



const setAuthorizationToken = (token: string) => {
    if (token) {
        console.log("set authorization token");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
        delete axios.defaults.headers.common['Authorization']
    }
}

export default setAuthorizationToken