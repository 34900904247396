import React from "react";
import { Link } from "react-router-dom";
import { useSession } from "../hooks/use-sesison";
import { Box, Button, Container, Typography } from "@mui/material";
import axios from "axios";

const LoginLink = () => {
    const [session, isLoggedIn] = useSession();

    return (
        <div>
            {
                isLoggedIn ?
                    <div>
                        <p>
                            Hi {session.given_name}
                        </p >
                        <Button onClick={(e) => {
                            axios.get("http://127.0.0.1:8000/users/my");
                        }}>k</Button>
                    </div >
                    :
                    <Link to="/login">
                        Sign In
                    </Link>
            }
        </div>
    );
}


export const Home = () => {
    return (
        <Box>
            <Container>
                <Typography variant="h4">Welcome</Typography>
                <LoginLink />
                <Button onClick={(e) => {
                    axios.get("http://127.0.0.1:8000/items");
                }}>items</Button>
            </Container>
        </Box>
    )
}