import { Box, Stack } from "@mui/material";
import { GoogleLoginButton } from "react-social-login-buttons";
import KakaoLoginButton from "./kakao_button";


const getGoogleLoginUrl = () => {
    const AUTH_URI = "https://accounts.google.com/o/oauth2/v2/auth"
    const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const REDIRECT_URI = "http://localhost:3000/login/oauth/redirected"
    const RESPONSE_TYPE = "code"
    const STATE = "google"
    const SCOPE = "email profile openid"
    return `${AUTH_URI}?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&state=${STATE}&scope=${SCOPE}`;
}


const getKakaoLoginUrl = () => {
    const AUTH_URI = "https://kauth.kakao.com/oauth/authorize"
    const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID
    const REDIRECT_URI = "http://localhost:3000/login/oauth/redirected"
    const RESPONSE_TYPE = "code"
    const STATE = "kakao"
    return `${AUTH_URI}?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&state=${STATE}`;
}



const loginOauth = (provider: string) => {
    var url = null;
    switch (provider) {
        case 'google':
            url = getGoogleLoginUrl();
            break;
        case 'kakao':
            url = getKakaoLoginUrl();
            break;
    }
    if (url != null) {
        window.location.href = url;
    }
}

export const OauthLogin = () => {

    return (
        <Box sx={{ width: 500 }}>
            <Stack>
                <GoogleLoginButton onClick={() => loginOauth('google')}>
                    <span>Continue with Google</span>
                </GoogleLoginButton>
                <KakaoLoginButton onClick={() => loginOauth('kakao')}>
                    <span>Continue with Kakao</span>
                </KakaoLoginButton>

            </Stack>
        </Box >
    )
}