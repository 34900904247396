import React from "react";
import { OauthLogin } from "../components/oauth_login";
import { useSession } from "../hooks/use-sesison";
import { Box, Container } from "@mui/material";
import LoremIpsum from "react-lorem-ipsum";



export const Login = () => {

    const [, isLoggedIn] = useSession();

    return (<Container>
        {isLoggedIn ? (
            <Container>
                <h2>Already Logged In</h2>
            </Container>) : (
            <Container>
                <center>
                    <Box sx={{ width: 600 }}>
                        <LoremIpsum p={1} />
                        <OauthLogin />
                    </Box>
                </center>
            </Container>)
        }</Container>
    );

}
